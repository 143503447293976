<template>
  <div
    class="incident-article"
    :class="{ 'incident-article-left': isSenderMentIQ, 'incident-article-right': !isSenderMentIQ, 'incident-article-internal': isInternal }"
  >
    <b-card>
      <template #header>
        <h6 class="mb-0">
          <b-avatar
            v-if="isSenderMentIQ"
            rounded
            variant="transparent"
            :src="isDark ? require('@/assets/images/logo/ml/MIQ_Signet_White_RGB.png') : require('@/assets/images/logo/ml/MIQ_Signet_Farbverlauf_RGB.png')"
          />
          <b-avatar
            v-if="!isSenderMentIQ"
            rounded
            variant="info"
          />
          &nbsp;
          {{ from }} <small class="text-muted">{{ createdAt | formatDateTime }}</small>
        </h6>
      </template>

      <div
        ref="body"
        class="body"
        :class="{'body-collapsed': collapsed}"
        v-html="cleanedBody"
      />

      <b-form-checkbox
        v-if="isOverflowing"
        v-model="collapsed"
        class="mt-1 mb-2"
        size="sm"
        button
        button-variant="light"
      >
        {{ collapsed ? 'Read more' : 'Collapse' }}
      </b-form-checkbox>

      <div
        v-if="attachments.length > 0"
        class="attachments"
      >
        <hr>
        <h6>Attachments:</h6>
        <ul
          v-for="attachment in attachments"
          :key="attachment.id"
        >
          <li><b-link @click="downloadAttachment(attachment)">
            {{ attachment.filename }}
          </b-link> {{ attachment.size | prettyBytes }}</li>
        </ul>
      </div>

    </b-card>
  </div>
</template>

<script>

import {
  BCard, BAvatar, BLink, BFormCheckbox,
} from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import IncidentService from '@/service/incident.service'

export default {
  components: {
    BCard,
    BAvatar,
    BLink,
    BFormCheckbox,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    ticketId: {
      type: Number,
      required: true,
    },
    from: {
      type: String,
      default: 'Unknown',
    },
    senderType: {
      type: Number,
      default: 0,
    },
    createdAt: {
      type: String,
      default: () => null,
    },
    body: {
      type: String,
      default: '',
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    isInternal: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { skin } = useAppConfig()

    const isDark = computed(() => skin.value === 'dark')

    return {
      isDark,
    }
  },
  data() {
    return {
      isMounted: false,
      cleanedBody: this.$props.body,
      collapsed: true,
    }
  },
  computed: {
    isSenderMentIQ() {
      // 0 = System / 1 = Agent / 2 = Customer
      return this.senderType === 0 || this.senderType === 1
    },
    isOverflowing() {
      if (this.isMounted) {
        const element = this.$refs.body
        return (element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth)
      }

      return false
    },
  },
  mounted() {
    this.isMounted = true
    this.replaceInlineAttachments()

    // scroll to bottom of page after article has been mounted (does not work on incidentDetail.vue)
    this.$nextTick(() => {
      window.scrollTo(0, document.body.scrollHeight)
    })
  },
  methods: {
    collapse(collapse) {
      this.collapsed = collapse
    },
    downloadAttachment(attachment) {
      IncidentService.getAttachmentAsync(this.ticketId, this.id, attachment.id, { disableTenantFilter: true })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
          const url = window.URL.createObjectURL(new Blob([result]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', attachment.filename)
          document.body.appendChild(link)
          link.click()
          link.remove()// you need to remove that elelment which is created before.
        })
    },
    replaceInlineAttachments() {
      // find attachments which can be displayed inline, retrieve these attachments and replace display object in body to display it inline
      for (let i = 0; i < this.attachments.length; i += 1) {
        let mimeType = null
        if (this.attachments[i].filename.endsWith('.jpg') || this.attachments[i].filename.endsWith('.jpeg')) {
          mimeType = 'image/jpeg'
        }
        if (this.attachments[i].filename.endsWith('.png')) {
          mimeType = 'image/png'
        }

        if (mimeType) {
          IncidentService.getAttachmentAsync(this.ticketId, this.id, this.attachments[i].id, { disableTenantFilter: true })
          // eslint-disable-next-line no-unused-vars
            .then(result => {
              const inlineImgSrc = `/api/v1/ticket_attachment/${this.ticketId}/${this.id}/${this.attachments[i].id}?view=inline`
              const data = `data:${mimeType};base64,${Buffer.from(result).toString('base64')}`

              this.cleanedBody = this.cleanedBody.replace(inlineImgSrc, data)
            })
        }
      }
    },
  },
}

</script>

<style>

.body {
  overflow: hidden;
}

.body.body-collapsed {
  max-height: 6.5em;
}
</style>
