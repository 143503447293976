<template>
  <div class="incident-detail">
    <b-overlay :show="isSaving || isLoading">

      <portal
        v-if="isHost"
        to="breadcrumb-right"
      >
        <b-dropdown
          size="sm"
          text="Manage"
          right
          style="z-index: 999"
        >
          <template #button-content>
            <feather-icon
              icon="SettingsIcon"
            />
          </template>
          <b-dropdown-item
            v-if="isHost"
            :to="{ name: 'zammad:ticket', meta: {id: incident.id }}"
          >
            <feather-icon
              icon="LinkIcon"
            />
            <span class="ml-1 text-middle">
              Open in Zammad
            </span>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$can('__host__Core.Incidents.Synchronize')"
            @click="synchronize"
          >
            <feather-icon
              icon="RefreshCwIcon"
            />
            <span class="ml-1 text-middle">
              Synchronize
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </portal>

      <div
        ref="stickyNav"
        class="sticky-nav"
      >
        <b-card
          v-if="!headerIsSticky"
          :title="incident.title"
        >
          <b-row>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr v-if="fullTicketNumber">
                    <th>Ticket number</th>
                    <td> {{ fullTicketNumber }}</td>
                  </tr>
                  <tr v-if="incident.asset">
                    <th>Asset</th>
                    <td><b-link :to="{ name: 'asset-to-asup-redirect', params: { id: incident.asset.id }, query: { ar: true } }">
                      {{ incident.asset.systemNumber }}
                    </b-link></td>
                  </tr>
                  <tr v-if="incident.asset && incident.asset.hostname">
                    <th>Asset Hostname</th>
                    <td> <b-link :to="{ name: 'asset-to-asup-redirect', params: { id: incident.asset.id }, query: { ar: true } }">
                      {{ incident.asset.hostname }}
                    </b-link></td>
                  </tr>
                  <tr>
                    <th>Status</th>
                    <td> {{ incident.status | incidentStatusDisplayName }}</td>
                  </tr>
                  <tr v-if="incident.pendingTime">
                    <th>Wait until</th>
                    <td> {{ incident.pendingTime | formatDateTime }}</td>
                  </tr>
                  <tr v-if="incident.severity">
                    <th>Severity</th>
                    <td> {{ incident.severity }}</td>
                  </tr>
                  <tr v-if="incident.createdOn">
                    <th>Created on</th>
                    <td> {{ incident.createdOn | formatDateTime }}</td>
                  </tr>
                  <tr v-if="incident.updatedOn">
                    <th>Updated on</th>
                    <td> {{ incident.updatedOn | formatDateTime }}</td>
                  </tr>
                  <tr v-if="incident.duration">
                    <th>Duration</th>
                    <td> {{ incident.duration | formatClrTimeSpan }}</td>
                  </tr>
                  <tr>
                    <th>Customer Ticket Ref. Id</th>
                    <td>
                      <div v-if="$can('Core.Incidents.Update')">
                        <span v-if="!isCustomerRefIdInEditMode">
                          {{ incident.customerRefId }}
                        </span>
                        <div v-if="isCustomerRefIdInEditMode">
                          <validation-observer
                            ref="customerRefIdRules"
                            tag="form"
                          >
                            <validation-provider
                              v-slot="validationContext"
                              name="customerRefId"
                              :rules="{ required: false, max: 120 }"
                            >
                              <b-form-group>
                                <b-input-group size="sm">
                                  <b-form-input
                                    id="input-customerRefId"
                                    v-model="updateData.customerRefId"
                                    placeholder="Insert customer ref. id"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="input-customerrefid-live-feedback"
                                  />
                                  <b-input-group-append>
                                    <b-button
                                      variant="outline-secondary"
                                      size="sm"
                                      @click="cancelCustomerRefIdEdit"
                                    >
                                      <feather-icon icon="XIcon" />
                                    </b-button>
                                  </b-input-group-append>
                                  <b-input-group-append>
                                    <b-button
                                      variant="outline-primary"
                                      size="sm"
                                      @click="saveCustomerRefId"
                                    >
                                      <feather-icon icon="SaveIcon" />
                                    </b-button>
                                  </b-input-group-append>
                                  <b-form-invalid-feedback id="input-customerrefid-live-feedback">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </b-input-group>
                              </b-form-group>
                            </validation-provider>
                          </validation-observer>
                        </div>
                        <small v-if="!isCustomerRefIdInEditMode && isOpen">
                          <b-link @click="isCustomerRefIdInEditMode = !isCustomerRefIdInEditMode">Edit</b-link>
                        </small>
                      </div>
                      <div v-else>
                        {{ incident.customerRefId }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr v-if="incident.ownerUser">
                    <th>Owner</th>
                    <td> {{ getUserDisplayName(incident.ownerUser) }}</td>
                  </tr>
                  <tr v-if="incident.createdByUser">
                    <th>Creator</th>
                    <td> {{ getUserDisplayName(incident.createdByUser) }}</td>
                  </tr>
                  <tr v-if="incident.partnerId">
                    <th>Partner Id</th>
                    <td> {{ incident.partnerId }}</td>
                  </tr>
                  <tr v-if="incident.vendorId">
                    <th>Vendor Id</th>
                    <td> {{ incident.vendorId }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>

          <template
            v-if="$can('Core.Incidents.Update')"
            slot="footer"
          >
            <b-button
              v-if="isOpen"
              class="float-right"
              size="sm"
              variant="outline-primary"
              @click="closeTicket"
            >
              Close ticket
            </b-button>
            <b-button
              v-if="!isOpen"
              class="float-right"
              size="sm"
              variant="outline-warning"
              @click="reopenTicket"
            >
              Reopen ticket
            </b-button>
          </template>
        </b-card>

        <b-card v-if="headerIsSticky">
          <strong>{{ fullTicketNumber }} / {{ incident.title }}</strong>
          <b-row>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr v-if="incident.asset">
                    <th>Asset</th>
                    <td><b-link :to="{ name: 'asset-to-asup-redirect', params: { id: incident.asset.id }, query: { ar: true } }">
                      {{ incident.asset.systemNumber }}
                    </b-link></td>
                  </tr>
                  <tr v-if="incident.asset && incident.asset.hostname">
                    <th>Asset Hostname</th>
                    <td> <b-link :to="{ name: 'asset-to-asup-redirect', params: { id: incident.asset.id }, query: { ar: true } }">
                      {{ incident.asset.hostname }}
                    </b-link></td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <th>Status</th>
                    <td> {{ incident.status | incidentStatusDisplayName }}</td>
                  </tr>
                  <tr v-if="incident.severity">
                    <th>Severity</th>
                    <td> {{ incident.severity }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
            <b-col>
              <table class="table table-sm table-borderless">
                <tbody>
                  <tr v-if="incident.ownerUser">
                    <th>Owner</th>
                    <td> {{ getUserDisplayName(incident.ownerUser) }}</td>
                  </tr>
                  <tr v-if="incident.createdByUser">
                    <th>Creator</th>
                    <td> {{ getUserDisplayName(incident.createdByUser) }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
        </b-card>
      </div>
      <div class="">
        <div
          v-for="article in articles"
          :key="article.id"
        >
          <incident-article
            :id="article.id"
            ref="articles"
            :ticket-id="incident.id"
            :from="article.from"
            :created-at="article.createdAt"
            :body="article.body"
            :attachments="article.inlineAttachments"
            :is-internal="article.internal"
            :sender-type="article.sender"
          />
        </div>
      </div>

      <b-card
        v-if="isOpen && $can('Core.Incidents.Update')"
        title="Add article"
      >

        <validation-observer
          ref="articleDescriptionRules"
          tag="form"
        >
          <validation-provider
            v-slot="validationContext"
            name="description"
            :rules="{ max: 10000 }"
          >
            <b-form-group>
              <b-form-textarea
                v-model="newArticleText"
                placeholder="Type here..."
                rows="6"
                :state="getValidationState(validationContext)"
                aria-describedby="input-description-live-feedback"
              />
              <b-form-invalid-feedback id="input-description-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </validation-observer>

        <template
          #footer
        >
          <div class="float-right">
            <b-button
              variant="secondary"
              @click="newArticleText=''"
            >
              Reset
            </b-button>
            <b-button
              class="ml-1"
              variant="primary"
              @click="saveArticle"
            >
              Save
            </b-button>
          </div>
        </template>
      </b-card>

      <b-card
        v-if="incident.attachments && (incident.attachments.length > 0 || (isOpen && $can('Core.Incidents.Update')))"
        title="Attachments"
      >
        <ul
          v-for="attachment in incident.attachments"
          :key="attachment.id"
        >
          <li>
            <b-link @click="download(attachment.id)">
              {{ attachment.name }}
            </b-link>
            <small>({{ attachment.bytes | prettyBytes }} / {{ attachment.date | formatDateTime }})</small>
            <b-button
              variant="transparent"
              size="sm"
              title="Copy attachment link to clipboard"
              @click="copyAttachmentUrlToClipboard(attachment.id)"
            >
              <feather-icon
                icon="CopyIcon"
                size="12"
              />
            </b-button>
          </li>
        </ul>

        <b-form-group
          v-if="isOpen && $can('Core.Incidents.Update')"
          label="Attach files"
          description="The file is saved immediately after upload. "
        >
          <b-form-file
            v-model="uploadFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />

          <b-progress
            v-if="uploadFile"
            v-model="uploadProgress"
            :max="uploadFile.size"
          />
        </b-form-group>
      </b-card>

      <b-card
        v-if="isHost && $can('__host__Core.Incidents.Update')"
        title="Internal note"
      >
        <validation-observer
          ref="noteRules"
          tag="form"
        >
          <validation-provider
            v-slot="validationContext"
            name="note"
            :rules="{ max: 2000 }"
          >
            <b-form-group>
              <b-form-textarea
                v-model="updateData.internalNote"
                placeholder="Internal notes are visible to mentIQ only!"
                rows="6"
                :state="getValidationState(validationContext)"
                :disabled="!isOpen"
                aria-describedby="input-note-live-feedback"
              />
              <b-form-invalid-feedback id="input-note-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </validation-observer>
        <template
          v-if="isOpen"
          #footer
        >
          <div class="float-right">
            <b-button
              class="ml-1"
              variant="primary"
              @click="saveInternalNote"
            >
              Save
            </b-button>
          </div>
        </template>
      </b-card>
    </b-overlay>

    <div
      class="position-fixed"
      style="top: 50%; right: 0; z-index: 999;"
    >
      <b-button
        v-b-tooltip.hover.left
        style="padding: 10px; border-radius: 0; display: block"
        title="Scroll to top and see ticket details"
        @click="scrollToTop"
      >
        <feather-icon icon="ArrowUpIcon" />
      </b-button>
      <b-button
        v-b-tooltip.hover.left
        style="padding: 10px; border-radius: 0; display: block"
        title="Collapse / Expand all articles"
        @click="toggleCollapseAllArticle"
      >
        <feather-icon icon="Maximize2Icon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormFile, BProgress, BLink, BFormTextarea, BFormGroup, BButton, BDropdown, BDropdownItem, BFormInput, BInputGroup, BInputGroupAppend, BOverlay, BFormInvalidFeedback, VBTooltip,
} from 'bootstrap-vue'

import {
  // eslint-disable-next-line no-unused-vars
  required, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { mapGetters } from 'vuex'
import IncidentArticle from './incidentArticle.vue'

import IncidentService from '@/service/incident.service'
import '@/assets/scss/sticky-nav.scss'

const { BlockBlobClient } = require('@azure/storage-blob')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormFile,
    BProgress,
    BLink,
    BFormTextarea,
    BFormGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BFormInvalidFeedback,
    IncidentArticle,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      incident: {},
      uploadFile: null,
      uploadProgress: 0,
      newArticleText: '',
      isCustomerRefIdInEditMode: false,
      isSaving: false,
      isLoading: false,
      updateData: {
        internalNote: null,
        customerRefId: null,
      },
      collapseAllArticle: false,
      intersectionObserver: null,
      headerIsSticky: false,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    isOpen() {
      return this.incident.status === 1 // New
        || this.incident.status === 2 // Open
        || this.incident.status === 3 // Wait for reminder
        || this.incident.status === 7 // Wait for close
    },
    articles() {
      if (this.incident.articles && this.incident.articles.length > 0) {
        return this.incident.articles
          .concat()
          .sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1))
      }
      return []
    },
    fullTicketNumber() {
      if (this.incident.number) {
        const { number } = this.incident
        return `MIQ-T-${number}`
      }
      return ''
    },
  },
  watch: {
    uploadFile(val) {
      if (val) {
        this.uploadAttachment(val)
      }
    },
  },
  created() {
    this.loadData()
  },
  mounted() {
    // eslint-disable-next-line no-unused-vars
    document.addEventListener('scroll', event => {
      const lastKnownScrollPosition = window.scrollY

      if (lastKnownScrollPosition === 0) {
        this.headerIsSticky = false
        this.$refs.stickyNav.classList.remove('is-sticked')
        document.querySelector('.header-navbar').style.display = 'block'
        document.querySelector('.header-navbar-shadow').style.display = 'block'
      } else {
        this.$refs.stickyNav.classList.add('is-sticked')
        document.querySelector('.header-navbar').style.display = 'none'
        document.querySelector('.header-navbar-shadow').style.display = 'none'
        this.headerIsSticky = true
      }
    })
  },
  beforeDestroy() {
    document.querySelector('.header-navbar').style.display = 'block'
    document.querySelector('.header-navbar-shadow').style.display = 'none'
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    refArticle(articleId) {
      return this.$refs.articles.find(ref => ref.id === articleId)
    },
    toggleCollapseAllArticle() {
      for (let i = 0; i < this.articles.length; i += 1) {
        const ref = this.refArticle(this.articles[i].id)
        ref.collapse(this.collapseAllArticle)
      }

      this.collapseAllArticle = !this.collapseAllArticle
    },
    scrollToTop() {
      document.body.scrollIntoView({ behavior: 'smooth' })
    },
    loadData() {
      this.isLoading = true
      IncidentService.getAsync(this.$route.params.id, { disableTenantFilter: true })
        .then(result => {
          this.incident = result

          this.updateData.internalNote = this.incident.note
          this.updateData.customerRefId = this.incident.customerRefId
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    getUserDisplayName(user) {
      if (user) {
        if (user.firstname && user.lastname) {
          return `${user.firstname} ${user.lastname}`
        }

        return user.email
      }

      return null
    },
    // eslint-disable-next-line no-unused-vars
    uploadAttachment(file) {
      IncidentService.getAttachmentUploadLinkAsync(file.name, { disableTenantFilter: true })
        .then(result => {
          // eslint-disable-next-line no-unused-vars
          const { uploadUrl, attachmentId } = result
          const self = this

          const blockBlobClient = new BlockBlobClient(uploadUrl)
          blockBlobClient.uploadData(file, {
            onProgress(event) {
              self.uploadProgress = event.loadedBytes
            },
            blobHTTPHeaders: {
              blobContentType: file.type,
            },
          })
            .then(() => {
              this.isSaving = true

              IncidentService.updateAsync(this.$route.params.id, {
                note: this.incident.note,
                customerRefId: this.incident.customerRefId,
                attachments: [{
                  id: attachmentId,
                  name: file.name,
                }],
              }, { disableTenantFilter: true })
                .then(() => {
                  this.uploadFile = null
                  this.uploadProgress = 0
                  this.$toast.success('Attachment uploaded', {
                    icon: true,
                  })
                })
                .finally(() => {
                  this.loadData()
                  this.isSaving = false
                })
            })
        })
    },
    download(attachmentId) {
      IncidentService.getAttachmentDownloadLinkAsync(this.$route.params.id, attachmentId, { disableTenantFilter: true })
        .then(result => {
          window.open(result.downloadUrl)
        })
    },
    copyAttachmentUrlToClipboard(attachmentId) {
      IncidentService.getAttachmentDownloadLinkAsync(this.$route.params.id, attachmentId, { disableTenantFilter: true })
        .then(result => {
          const input = document.createElement('textarea')
          input.innerHTML = result.downloadUrl
          document.body.appendChild(input)
          input.select()
          const copyResult = document.execCommand('copy')
          document.body.removeChild(input)

          if (copyResult === true) {
            this.$toast.success('Attachment link copied to clipboard. The link is valid for 48 hours.', {
              icon: true,
            })
          } else {
            this.$toast.error('Failed to copy attachment link to clipboard.', {
              icon: true,
            })
          }
        })
    },
    cancelCustomerRefIdEdit() {
      this.isCustomerRefIdInEditMode = false
      this.updateCustomerRefId = this.incident.customerRefId // reset to original value
    },
    saveCustomerRefId() {
      this.$refs.customerRefIdRules.validate().then(success => {
        if (success) {
          this.isSaving = true

          IncidentService.updateAsync(this.$route.params.id, {
            note: this.incident.note,
            customerRefId: this.updateData.customerRefId,
          }, { disableTenantFilter: true })
            .then(() => {
              this.$toast.success('Updated', {
                icon: true,
              })
            })
            .finally(() => {
              this.loadData()
              this.isSaving = false
              this.isCustomerRefIdInEditMode = false
            })
        } else {
          this.$toast.error('Input is invalid.', {
            icon: true,
          })
        }
      })
    },
    saveInternalNote() {
      this.$refs.noteRules.validate().then(success => {
        if (success) {
          this.isSaving = true

          IncidentService.updateAsync(this.$route.params.id, {
            note: this.updateData.internalNote,
            customerRefId: this.incident.customerRefId,
          }, { disableTenantFilter: true })
            .then(() => {
              this.$toast.success('Updated', {
                icon: true,
              })
            })
            .finally(() => {
              this.loadData()
              this.isSaving = false
            })
        } else {
          this.$toast.error('Input is invalid.', {
            icon: true,
          })
        }
      })
    },
    saveArticle() {
      this.$refs.articleDescriptionRules.validate().then(success => {
        if (success) {
          if (this.newArticleText == null || this.newArticleText.length === 0) {
            this.$toast.error('Input is invalid.', {
              icon: true,
            })
            return
          }

          this.isSaving = true

          IncidentService.createArticleAsync(this.$route.params.id, {
            description: this.newArticleText,
          }, { disableTenantFilter: true })
            .then(() => {
              this.newArticleText = null
              this.loadData()
              this.$toast.success('Updated', {
                icon: true,
              })
            })
            .finally(() => {
              this.loadData()
              this.isSaving = false
            })
        } else {
          this.$toast.error('Input is invalid.', {
            icon: true,
          })
        }
      })
    },
    closeTicket() {
      this.isSaving = true
      IncidentService.closeAsync(this.$route.params.id, { disableTenantFilter: true })
        .then(() => {
          this.loadData()
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    reopenTicket() {
      this.isSaving = true
      IncidentService.reopenAsync(this.$route.params.id, { disableTenantFilter: true })
        .then(() => {
          this.loadData()
        })
        .finally(() => {
          this.isSaving = false
        })
    },
    synchronize() {
      this.isSaving = true
      IncidentService.triggerSynchronizeAsync(this.$route.params.id)
        .then(() => {
          this.$toast.success('Synchronization triggered. This can take a while. Please refresh the page after a few seconds.', {
            icon: true,
          })
        })
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}

</script>

<style lang="scss">
  @import "@/assets/scss/incident.scss";
</style>
